$text-color: rgb(0, 0, 0) !default;

* {
    outline: 0;
}

html,
body {
    color: $text-color;
    line-height: rem-calc(28);
    font-family: $body-font-family;
}

body {
    overflow-y: scroll;
}

p, a  {
    color: $grey;
}
