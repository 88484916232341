@import 'collors';

$text-color: $black;

// Breakpoints
$breakpoints: (
  small: 0,
  medium: 520px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large xlarge xxlarge);

//Fonts
$body-font-family: 'Muli', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$small-header-font-family: 'Spectral', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$header-font-family: 'Niveau Grotesk', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Buttons
$button-color: $white;
$button-color-alt: $white;
$button-padding: rem-calc(13) rem-calc(42);
$button-font-family: $small-header-font-family;
$button-font-weight: $font-weight-medium;

// Grid
$grid-container-padding: (
  small: rem-calc(20),
  medium: rem-calc(30),
  large: rem-calc(40),
);
$grid-container: map_get($breakpoints, xxlarge);

$title-height: rem-calc(41);

$content-text-line-height: rem-calc(24);


// Inputs
$input-line-height: 39;
$input-border: 1px solid rgba(138,138,138,255);

// close button
$closebutton-size: (
  small: 2em,
  medium: 3em
);
$closebutton-color: $black;
$closebutton-color-hover: $dark-gray;
$closebutton-offset-horizontal: rem-calc(31);