$input-color: rgb(41, 41, 41) !default;
$input-error: #ff0033 !default;
$label-color: rgb(196, 196, 196) !default;
$type-accent-color: #000000 !default;

form {
  .error {
    border: 1px solid $input-error;
  }

  .errors {
    color: $input-error;
    font-size: rem-calc(12);
    margin: 10px 0 10px 0;
  }

  input {
    line-height: rem-calc(54px) !important;
  }

  label, a {
    padding: 5px 0 5px 0;
  }

  a {
    text-decoration: underline;
  }

  [type=file], [type=checkbox], [type=radio] {
    accent-color: $type-accent-color;
  }

  [type=file] {
    display: none;
  }

  select {
    line-height: 1.15 !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: none !important;
  }

  select:disabled, input:disabled, [aria-disabled="true"] {
    background-color: #ffffff !important;
    opacity: 0.5;
  }

  .select-wrapper {
    display: block;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: rem-calc(20);
      right: rem-calc(23);
      width: rem-calc(11);
      height: rem-calc(11);
      border-bottom: 1px solid $input-color;
      border-left: 1px solid $input-color;
      transform: rotate(-45deg);
      pointer-events: none;
    }
  }

  .checkbox-with-label {
    @include flex();
    align-items: baseline;
  }

  .password-toggle {
    float: right;
    margin-top: 10px;
  }

  label[for=password], label[for=passwordRepeat] {
    display: inline-flex;
  }
}

.verify a {
  text-decoration: none !important;
}